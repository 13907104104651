import React, { useEffect, useState } from 'react';
import { SSRProvider } from '@react-aria/ssr'; // handles warning from FormControl
import { Helmet } from 'react-helmet';
import { Container, Col, Row } from 'reactstrap';

import GuidelinesModal from 'routes/Products/components/GuidelinesModal/';
import GeneralModal from 'components/GeneralModal';
import Profile from 'routes/User/Profile/containers';
import { CommunicationPreferences } from 'routes/User/Profile/components/CommunicationPreferences';

// library imports
import { AppContextProvider } from '@frn/frn-react-native-discourse/lib/module/context/AppContextProvider';
import Forum from '@frn/frn-react-native-discourse/lib/module/components/Forum/Forum';
import { track } from '../../lib/track';
const baseEnvVars = {
  FORUM_BASE_URL: process.env.FORUM_BASE_URL,
  FORUM_API_URL: process.env.FORUM_API_URL,
  FORUM_DISCOURSE_API_USERNAME_PREFIX: process.env.FORUM_DISCOURSE_API_USERNAME_PREFIX,
  DEFAULT_SELECTED_CATEGORY_PRODUCTS: process.env.DEFAULT_SELECTED_CATEGORY_PRODUCTS,
  DMABLE_PRODUCTS: process.env.DMABLE_PRODUCTS,
  GROUP_DMABLE_PRODUCTS: process.env.GROUP_DMABLE_PRODUCTS
};

const ForumWrapper = props => {
  const { isCommunityForum } = props;
  useEffect(() => {
    if (isCommunityForum) {
      const head = document.getElementsByTagName('head')[0];
      const css = '#beacon-container{display:none;}';
      const style = document.createElement('style');

      style.id = 'forum-beacon-style';
      style.type = 'text/css';
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);

      // prevent double scroll when in forum view
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      const styleElem = document.getElementById('forum-beacon-style');
      styleElem && styleElem.remove();
      // reset outer scroll bar during dismount
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      <Helmet>
        <script src="https://cdn.foodrevolution.org/scripts/message-bus-ajax.min.js" type="text/javascript"></script>
      </Helmet>
      <SSRProvider>
        <AppContextProvider>
          <ForumWrapperInner {...props} />
        </AppContextProvider>
      </SSRProvider>
    </>
  );
};

const ForumWrapperInner = props => {
  const [isGuidelineModalOpen, setIsGuidelineModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [profileUserId, setProfileUserId] = useState(null);
  const [userAllowsDMs, setUserAllowsDMs] = useState(false);
  const [openCPModal, setOpenCPModal] = useState(false);
  const [dispatchForumAuth, setDispatchForumAuth] = useState(null);

  useEffect(() => {
    // toggle back off
    if (dispatchForumAuth) setDispatchForumAuth(false);
  }, [dispatchForumAuth]);

  const envVars = {
    ...baseEnvVars,
    PRODUCT: props.product
  };

  envVars.IS_OUTSIDE_FORUM = props.forumType == 'Forum' ? false : true;

  // platform only: hide medical disclaimer
  const StyleBlock = () => {
    return (
      <style>{`
            .medicalDisclaimer{display:none;}
          `}</style>
    );
  };

  //used to show the user profile modal
  const toggleModal = ({ publicProfileUserId, openCommunicationPreferencesModal, isDMable }) => {
    if (openCommunicationPreferencesModal) {
      setOpenCPModal(true);
    }
    setUserAllowsDMs(isDMable ? true : false);
    setProfileUserId(publicProfileUserId);
    publicProfileUserId && setIsProfileModalOpen(!isProfileModalOpen);
  };

  // simple url parse routine
  const urlToPath = () => {
    let path = null;
    const urlParts = location.hash.split('/');
    if (urlParts[1] === 'topic' || 'reply') {
      if (urlParts[2]) {
        path = {
          post: urlParts[1],
          id: urlParts[2]
        };
      }
    }
    if (urlParts[1] === 'dm') {
      path = {
        post: urlParts[1],
        ...(urlParts[2] && { id: urlParts[2] })
      };
    }
    return path;
  };
  //need to track forum events:  post, reply (these are both posts in the forum)
  //rudderstack
  // this is what is already passed in to forumWrapper
  /*
 product={this.productInPath}
                scrollToMe={this.scrollToContent}
                slug={slug}
                pageTitle={title}
                passedReactRef={this.highlightedComment}
                user={user}
                refreshToken={this.props.refreshToken}
                revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                forumType="ContentItem"
                */
  //need service (recipe, week, etc) to be passed in or default to 'forum'

  const trackForum = ({ interaction }) => {
    const userAnalytics = {
      service: props.service ? props.service : 'forum', //the kind of service, like recipe, weekly guide, etcs
      slug: props.slug ? props.slug : '',
      title: props.pageTitle ? props.pageTitle : '',
      platform_user_id: props.user && props.user.userId,
      uuid: props.user && props.user.UUID,
      interaction: interaction ? interaction : 'post' //post or reply, from the forum code children
    };
    track('forum', userAnalytics, {}, res => {
      console.log('rudderstack resp', res);
    });
  };

  const [forumKey, setForumKey] = useState(null);

  useEffect(() => {
    let path = urlToPath();
    if (!forumKey && path) setForumKey({ path });
  }, []);

  const navigateToScreen = userId => {
    let path = urlToPath() || { post: 'dm' };
    let redirectPath = `/products/${props.product}/community/#!/dm`;
    redirectPath += path.id ? `/${path.id}` : '';
    props.history.push(redirectPath);
    path.username = `${baseEnvVars.FORUM_DISCOURSE_API_USERNAME_PREFIX}.${userId}`;
    setForumKey({ path });
    setIsProfileModalOpen(false);
  };

  return (
    <Container>
      <Col>
        {profileUserId && (
          <GeneralModal
            isOpen={isProfileModalOpen}
            toggle={() => {
              if (isProfileModalOpen) {
                if (profileUserId === props.user.id) setDispatchForumAuth(true); // we dispatch if user updated their own preferences
                setProfileUserId(null); // reset upon close
              }
              setIsProfileModalOpen(!isProfileModalOpen);
            }}
            component={() => {
              return (
                <Profile
                  publicProfileUserId={profileUserId}
                  showSummary={true}
                  openCPModal={openCPModal}
                  allowsDMs={userAllowsDMs}
                  navigateToScreen={navigateToScreen}
                />
              );
            }}
          />
        )}
        {props.forumType == 'Forum' && (
          <GuidelinesModal
            isOpen={isGuidelineModalOpen}
            toggle={() => setIsGuidelineModalOpen(!isGuidelineModalOpen)}
          />
        )}
        <Forum
          {...props}
          isCommunityForum
          forumKey={forumKey}
          isGuidelineModalOpen={isGuidelineModalOpen}
          setIsGuidelineModalOpen={setIsGuidelineModalOpen}
          toggleProfileModal={toggleModal}
          dispatchForumAuth={dispatchForumAuth}
          envVars={envVars}
        />
        <StyleBlock />
      </Col>
    </Container>
  );
};

export { baseEnvVars, ForumWrapper };
